<template>
  <div class="appContainer userBox flex-between">
    <div class="leftBox bb ">
      <div class="flex-between header">
        <el-input
          placeholder="请输入内容"
          v-model="leftForm.filter"
          @input="searchFn"
          clearable
        />
        <el-tooltip effect="dark" content="新增" placement="top-start">
          <el-button
            icon="el-icon-plus"
            style="margin-left:10px"
            class="font18"
            type="text"
            @click="addFn"
          />
        </el-tooltip>
      </div>
      <el-scrollbar
        class="list"
        wrap-class="scrollbar-wrapper"
        v-if="userList.length"
        v-loading="loadingList"
      >
        <span
          v-for="(item, key) in userList"
          class="pointer text flex-between"
          :key="key"
          @click="selectUserFn(item)"
          :class="{ active: item.userId == currentId }"
        >
          <span>{{ item.nickname }}</span>
          <el-popconfirm title="确定删除该用户吗？" @confirm="deleUserFn(item)">
            <el-button
              type="danger"
              size="mini"
              class="deleBtn "
              slot="reference"
              >删除</el-button
            >
          </el-popconfirm>
        </span>
      </el-scrollbar>
      <p v-else class="empty bb text-center">暂无数据</p>
    </div>
    <!-- 右边详情 -->
    <div class="rightBox bb" v-loading="loadingDetial">
      <template v-if="currentId">
        <el-card
          class="box-card baseInfo"
          v-for="item in cardList"
          :key="item.name"
        >
          <div slot="header" class="clearfix">
            <span class="fW600">{{ item.name }}</span>
          </div>
          <template v-if="item.id == 'base'">
            <div class="line-box">
              <b>用户名：</b>
              <span class="text">{{ userDetial.username }}</span>
            </div>
            <div class="line-box">
              <b>密码：</b>
              <span class="text">
                <label v-show="!isEditPwd">***********</label>
                <el-popover placement="top" width="160" v-model="visible">
                  <p>
                    <span class="el-icon-info red" />
                    确定要重置密码么？
                  </p>
                  <div style="text-align: right; margin: 0">
                    <el-button size="mini" type="text" @click="visible = false"
                      >取消</el-button
                    >
                    <el-button type="primary" size="mini" @click="resetPwdFn"
                      >确定</el-button
                    >
                  </div>
                  <label slot="reference" class="pointer font16 reset"
                    >重置</label
                  >
                </el-popover>
              </span>
            </div>
            <div class="line-box">
              <b>姓名：</b>
              <span class=" pointer text" @dblclick="dbItemFn('nickname')">
                <el-tooltip
                  class="tooltip"
                  effect="dark"
                  content="双击编辑姓名"
                  placement="top-start"
                >
                  <label v-show="!isEditNickName">{{ form.nickname }}</label>
                </el-tooltip>
                <editInput
                  ref="editInputChild"
                  v-show="isEditNickName"
                  :name="form.nickname"
                  :width="'300px'"
                  :placeholder="'请输入姓名'"
                  :word_limit_length="64"
                  :inputType="'text'"
                  @inputBlur="inputChange('nickname', $event)"
                />
              </span>
            </div>
            <div class="line-box">
              <b>手机号：</b>
              <span
                @dblclick="dbItemFn('cellphone')"
                class=" pointer tooltip text"
              >
                <el-tooltip
                  effect="dark"
                  content="双击编辑手机号码"
                  placement="top-start"
                >
                  <template v-if="!editCellPhone">
                    <label v-if="form.cellphone && form.cellphone != ''">{{
                      form.cellphone
                    }}</label>
                    <label v-else>空</label>
                  </template>
                </el-tooltip>
                <el-input
                  ref="cellphoneInput"
                  class="inputwidth"
                  clearable
                  v-if="editCellPhone"
                  placeholder="请输入手机号"
                  v-model.trim="form.cellphone"
                  @blur="inputChange('cellphone')"
                  @keyup.enter.native="$event.target.blur"
                />
              </span>
            </div>
            <div class="line-box">
              <b>邮箱：</b
              ><span
                @dblclick="dbItemFn('email')"
                class=" pointer tooltip text"
              >
                <el-tooltip
                  effect="dark"
                  content="双击编辑邮箱"
                  placement="top-start"
                >
                  <template v-if="!editEmail">
                    <label v-if="form.email && form.email != ''">{{
                      form.email
                    }}</label>
                    <label v-else>空</label></template
                  >
                </el-tooltip>
                <el-input
                  ref="emailInput"
                  class="inputwidth"
                  clearable
                  v-if="editEmail"
                  placeholder="请输入邮箱"
                  v-model.trim="form.email"
                  @blur="inputChange('email')"
                  @keyup.enter.native="$event.target.blur"
                />
              </span>
            </div>
          </template>
          <template v-if="item.id == 'weChat'">
            <div class="line-box">
              <b>企业微信ID：</b
              ><span
                class="text"
                v-if="
                  userDetial.workWechatUserId &&
                    userDetial.workWechatUserId != ''
                "
                >{{ userDetial.workWechatUserId }}</span
              >
              <span v-else>空</span>
            </div>
          </template>
          <template v-if="item.id == 'status'">
            <div class="line-box">
              <b>最后一次登陆时间：</b
              ><span class="text">{{
                formatDate(userDetial.lastVisitTime)
              }}</span>
            </div>
            <div class="line-box">
              <b>有效状态：</b
              ><span class="text">
                <el-radio-group v-model="disabled" @change="changeRadioFn">
                  <el-radio label="0">启用</el-radio>
                  <el-radio label="1">注销</el-radio>
                </el-radio-group>
              </span>
            </div>
          </template>
        </el-card>
      </template>
      <el-empty
        v-if="!currentId"
        style="margin-top:200px"
        description="暂无数据，请点击左侧列表"
      />
    </div>
    <addDialog
      :showDialog="showDialog"
      @closeDialog="addCloseDialogFn"
      @sureDialog="addSureDialogFn"
    />
    <confirmDialog
      :showConfirmDialog="showConfirmDialog"
      :confirmData="confirmAction"
      @closeConfirm="closeConfirmFn"
      @sureConfirm="sureConfirmFn"
    >
      <span
        >确认<label class="red"> {{ confirmAction.action }} </label>此账号【{{
          confirmAction.nickname
        }}】吗？</span
      >
    </confirmDialog>
  </div>
</template>
<script>
import {
  users,
  A_user,
  addUser,
  editUser,
  resetPwd,
  remove_user,
  if_repeat
} from '@/api/userLimit';
import { release } from 'os';
export default {
  name: 'User',
  components: {
    addDialog: () => import('./components/addDialog.vue'),
    confirmDialog: () => import('@/views/components/confirmDialog.vue'),
    editInput: () => import('@/views/components/editInput')
  },
  data() {
    return {
      userList: [],
      loadingList: false,
      loadingDetial: false,
      currentId: null,
      userDetial: {},
      disabled: '1',
      form: {
        cellphone: '',
        email: '',
        disabled: null, //有效状态 true 启用
        nickname: ''
      },
      editCellPhone: false,
      editEmail: false,
      showDialog: false,
      showConfirmDialog: false,
      confirmAction: {
        action: '',
        nickname: ''
      },
      confirmDialogType: '',
      leftForm: {
        pageNumber: 1,
        pageSize: 1000000,
        filter: '',
        sort: 'username'
      },
      isEditPwd: false,
      cardList: [
        { name: '基本信息', id: 'base' },
        { name: '企业微信绑定', id: 'weChat' },
        { name: '状态', id: 'status' }
      ],
      visible: false,
      isEditNickName: false
    };
  },
  created() {
    this.getUserList();
  },
  methods: {
    deleUserFn(row) {
      remove_user({ id: this.currentId }).then(res => {
        this.$message.success('删除成功');
        this.getUserList();
        this.currentId = null;
        this.userDetial = null;
      });
    },
    formatDate(date) {
      if (!date) return;
      const nDate = new Date(date);
      const year = nDate.getFullYear();
      const month = nDate
        .getMonth()
        .toString()
        .padStart(2, 0);
      const day = nDate
        .getDay()
        .toString()
        .padStart(2, 0);
      const h = nDate
        .getHours()
        .toString()
        .padStart(2, 0);
      const mm = nDate
        .getMinutes()
        .toString()
        .padStart(2, 0);
      const s = nDate
        .getSeconds()
        .toString()
        .padStart(2, 0);
      return year + '-' + month + '-' + day + ' ' + h + ':' + mm + ':' + s;
    },
    sureConfirmFn() {
      this.showConfirmDialog = false;
      this.edit_A_User();
    },
    closeConfirmFn() {
      this.showConfirmDialog = false;
      if (this.confirmDialogType == 'radioStatus') {
        let a = this.disabled == '1' ? '0' : '1';
        this.$set(this, 'disabled', a);
      } else if (this.confirmDialogType == 'unLockStatus') {
      }
    },
    changeRadioFn() {
      this.confirmDialogType = 'radioStatus';
      this.showConfirmDialog = true;
      this.confirmAction.action = this.disabled == '0' ? '启用' : '注销';
    },
    async edit_A_User(cb) {
      this.loadingDetial = true;
      this.form.disabled = Number(this.disabled);
      // console.log({ ...this.userDetial, ...this.form })
      // return
      try {
        const res = await editUser({ ...this.userDetial, ...this.form });
        this.loadingDetial = false;
        this.$message.success('保存成功');
        cb && cb();
      } catch {
        this.loadingDetial = false;
      }
    },
    addSureDialogFn(v) {
      // this.addUser(v)
      addUser(v).then(res => {
        this.$message.success('新增成功');
        this.showDialog = false;
        this.getUserList();
      });
    },
    addCloseDialogFn() {
      this.showDialog = false;
    },
    addFn() {
      this.showDialog = true;
    },
    resetPwdFn() {
      this.visible = false;
      this.resetPwd();
    },
    async resetPwd() {
      const res = await resetPwd({ userId: this.currentId });
      this.$message.success('密码重置成功，请查收短信');
    },

    inputChange(type, v) {
      if (type == 'cellphone') {
        let reg = /^[1][3,4,5,7,8,9][0-9]{9}$/;
        if (!reg.test(this.form.cellphone)) {
          this.$message.warning('请输入正确的手机号');
          return;
        }
        this.if_repeat('cellphone', this.form.cellphone, res => {
          if (res) {
            this.$message.warning('手机号已存在，请重新输入');
            return;
          } else {
            this.edit_A_User(() => {
              this.editCellPhone = false;
            });
          }
        });
      } else if (type == 'email') {
        let reg = /^\w+@[a-zA-Z0-9]{2,10}(?:\.[a-z]{2,4}){1,3}$/;
        if (!reg.test(this.form.email)) {
          this.$message.warning('请输入正确的邮箱');
          return;
        }
        this.if_repeat('email', this.form.email, res => {
          if (res) {
            this.$message.warning('邮箱已存在，请重新输入');
            return;
          } else {
            this.edit_A_User(() => {
              this.editEmail = false;
            });
          }
        });
      } else if (type == 'nickname') {
        this.form.nickname = v;
        this.edit_A_User(() => {
          this.isEditNickName = false;
          this.getUserList();
        });
      }
    },
    if_repeat(name, value, cb) {
      if_repeat({
        id: this.currentId,
        name: name,
        value: value
      }).then(res => {
        cb(res);
      });
    },

    //双击
    dbItemFn(v) {
      if (v == 'cellphone') {
        this.editCellPhone = true;
        this.$nextTick(_ => {
          this.$refs.cellphoneInput[0].$refs.input.focus();
        });
      } else if (v == 'email') {
        this.editEmail = true;
        this.$nextTick(_ => {
          this.$refs.emailInput[0].$refs.input.focus();
        });
      } else if (v == 'nickname') {
        this.isEditNickName = true;
        this.$nextTick(_ => {
          this.$refs.editInputChild[0].$refs.nameInput.$refs.input.focus();
        });
      }
    },
    searchFn(v) {
      this.getUserList();
    },

    async getUserList() {
      this.loadingList = true;
      const res = await users(this.leftForm);
      try {
        this.userList = res.content || [];
        this.loadingList = false;
      } catch {
        this.loadingList = false;
      }
    },
    async get_A_user() {
      this.loadingDetial = true;
      const res = await A_user({ userId: this.currentId });
      try {
        this.userDetial = res;
        this.form.cellphone = this.userDetial.cellphone;
        this.form.email = this.userDetial.email;
        this.form.nickname = this.userDetial.nickname;
        this.disabled = this.userDetial.disabled + '';
        this.confirmAction.nickname = this.userDetial.nickname;
        this.loadingDetial = false;
      } catch {
        this.loadingDetial = false;
      }
    },
    selectUserFn(v) {
      this.currentId = v.userId.toString() || null;
      this.default_A_user();
      this.get_A_user();
    },
    default_A_user() {
      this.editCellPhone = false;
      this.editEmail = false;
      this.isEditNickName = false;
    }
  }
};
</script>
<style scoped lang="scss">
::v-deep .el-scrollbar__wrap {
  overflow-x: hidden;
}
.header {
  height: 40px;
  padding-bottom: 10px;
  border-bottom: 1px solid #e5e5e5;
}
.list {
  margin-top: 8px;
  height: calc(100% - 60px);
  .text {
    padding: 5px 10px;
    line-height: 20px;
    margin-bottom: 8px;
    .deleBtn {
      display: none;
      padding: 2px;
    }
    &:hover {
      background: #e8f1ff;
      .deleBtn {
        display: inline-block;
      }
    }
  }
  .active {
    background: #e8f1ff;
    .deleBtn {
      display: inline-block;
    }
  }
}

.box-card {
  min-height: 100px;
  margin-top: 10px;
  .line-box {
    // text-align: center;
    line-height: 32px;
    margin-bottom: 10px;
    b {
      width: 160px;
      text-align: right;
      display: inline-block;
      margin-right: 5%;
    }
    .text {
      min-width: 100px;
      text-align: left;
      display: inline-block;
    }
  }
  .reset {
    margin-left: 15px;
    color: #409eff;
  }
  .sendLabel {
    color: #409eff;
  }
}
.red {
  color: red;
}
.tooltip {
  min-width: 200px;
}
.inputwidth {
  width: 300px;
}
</style>
