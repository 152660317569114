import request from "@/utils/request"

// 用户管理
export function users(params) {
    return request({
        url: "/manage/user/page",
        method: "get",
        params
    })
}
// 用户详情
export function A_user(params) {
    return request({
        url: `/manage/user/${params.userId}`,
        method: "get",
        // params
    })
}
// 新增后台门户用户 v1
export function addUser(data) {
    return request({
        url: "/manage/user",
        method: "put",
        data
    })
}
// 编辑后台门户用户 v1
export function editUser(data) {
    return request({
        url: "/manage/user",
        method: "post",
        data
    })
}
// 重置密码
export function resetPwd(params) {
  return request({
      url: `/manage/user/reset/${params.userId}`,
      method: "get",
      // params
  })
}
// 验证重复, true: 已存在重复的字段, false: 不存在
export function if_repeat(params) {
  return request({
      url: `/manage/user/duplicate`,
      method: "get",
      params
  })
}
// 删除用户
export function remove_user(data) {
  return request({
      url: `/manage/user/${data.id}`,
      method: "delete",
      data
  })
}